@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,300;6..12,400;6..12,500;6..12,600;6..12,700;6..12,800&display=swap');
@font-face {
  font-family: calibre;
  src: url(../public/calibre/CalibreRegular.otf);
}

@font-face {
  font-family: calibre-semi-bold;
  src: url(../public/calibre/CalibreSemibold.otf);
}
body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F5F6FA !important;
}

code {
  font-family: 'Nunito Sans', sans-serif;
}


*{
  margin: 0px;
  padding: 0px;
}

.link{
  text-decoration: none;
  color: inherit;
}

.loginForm-container {
  background-image: url('Images/team-leader-teamwork-concept_74855-6671-removebg-preview 1.png');
  background-color: #1976d2;
  background-size: cover;
  background-position: center;
  min-height: 100vh; /* Minimum height to cover the entire viewport */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.Notfound-container {
  background-image: url('Images/team-leader-teamwork-concept_74855-6671-removebg-preview 1.png');
  background-color: #1976d2;
  background-size: cover;
  background-position: center;
  min-height: 100vh; /* Minimum height to cover the entire viewport */
  display: flex;
  /* align-items: center; */
  justify-content: center;
  width: 100%;
}

.login-form{
  padding: 10px 15px 20px 15px;
}
.p-5px{
  padding: 5px;
}

.Mui-error{
  margin: 0 !important;
}

.css-108bkvk-MuiDrawer-docked {
  width: 30px !important;
}

.Icon-bg-primary-white{
  background-color: #fff;
}

.badge span{
  background-color: #fff;
  color: #568aff;
   
}
.email-accordion button{
  height: 56px;
    border-radius: 5px;
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    background: transparent;
    font-family: 'Nunito Sans';
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0);
}
.email-accordion button[type="button"] span {
  color: #333;
}
.email-accordion button[role="menuitemradio"] span svg{
  color: #FAFBFC;
  fill: transparent;
}
.email-accordion{
  border-radius: 5px;

}

.email-campaign .MuiDialog-paper .MuiSvgIcon-root ,.email-accordion button[type="button"] svg{
  color: #c5c5c5 !important;

}
.email-campaign .MuiDialog-paper {
  overflow-y: visible;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.custom-dropdown-toggle {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

.custom-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.custom-dropdown-item {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
}

.custom-dropdown-item input[type="radio"] {
  margin-right: 10px;
}

.profileMatch{
  padding: 10px 16px 0px 16px;
}

.profile-fileUpload{
  padding: 0 16px;
}
.fileUpload div{
  padding-top: 25px !important;
}

.button-t {
  border-radius: 5px !important;
  font-size: 18px;
  background-color: #1976d2 !important;
  color: #fff;
  width: 130px;
  height: 40px;
}
.button-j {
  width: 160px;
}
.bg-primary{
  background-color: #1976d2 !important;
  color: #fff;
}

.button-a:hover{
  background-color: #1976d2 !important;
  color: #fff !important;
}
.button-a:hover svg {
  color: #fff !important;
}

.button-j svg{
  background-color: #1976d2!important;
  color: #fff !important;
}

.text-size{
  font-size: 15px;
  line-height: 19.1px;
  font-weight: 700;
}
.MuiDialog-container .MuiTypography-root{
  padding: 5px 24px;
}

.upload-sec .MuiBox-root{
  width: 185px;
}
.upload-sec .MuiBox-root:hover{
  background-color: #F5F6FA;
  cursor: pointer;
}

.MuiDataGrid-columnHeader:hover svg {
color: #fff;
}

.css-7tsl1f-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled {
  color: rgb(0 0 0 / 49%);
  background-image: linear-gradient(to bottom, #ffffff 30%, #f3f3f3 70%);
  font-size: 1.2rem;
}
.Mui-disabled{
  background-color: #f3f3f3;
}

/* in add users textfield box size */
/* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 32px 10px 14px !important; 
} */

/* textfield for email */
/* #demo-simple-select-label{
  top: -5px;
} */

/* password textfield */
/* .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 32px 10px 14px !important;
} */
@media (max-width: 600px) {
  main {
    width: 100%;
  }
 
}
@media (min-width: 601px) and (max-width: 900px) {
  main {
    width: 100%;
  }
  .upload-sec .MuiBox-root {
    width: 100px;
}
}
@media (min-width: 901px) {
  main {
    width: 1200px;
  }
  .upload-sec .MuiBox-root {
    width: 185px;
}
}


.css-1v5z4dq-MuiPaper-root-MuiCard-root {
  border: 0px !important;
}


table.adduser_table{
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  overflow: hidden;
}

table td, table th {
  border-top: 1px solid #ECF0F1;
  padding: 10px;
  letter-spacing: 0.028em;
}
table td {
  /* border-left: 1px solid #ECF0F1;
  border-right: 1px solid #ECF0F1; */
  font-size: 1rem !important;
}
table td:first-child{
  border-left: 1px solid #ECF0F1
}
table td:last-child{
  border-right: 1px solid #ECF0F1;
}
table th {
  background-color: #1976d2;
  color: #fff !important;
  font-size: 1.1rem !important;
  font-weight: 600;

}
table tr:nth-of-type(even) td,
.MuiDataGrid-row:nth-child(even) {
  background-color: #1976d21c;
}
table .total th {
  background-color: white;
}
table .total td {
  text-align: right;
  font-weight: 700;
}

.adduser_table .input input {
  padding: 10px 14px !important;
 
}

.main_container ul li {
  padding: 0 !important;
}

.MuiDataGrid-cell{
  font-size: 14px !important;
}

.css-1ldru33-MuiGrid-root>.MuiGrid-item{
  padding-top: 0px !important;
}

.full-page-loader{
  width: 100%;
  position: absolute;
  height: 100vh;
  background: #c1bbbb54;
  z-index: 10;
}
.full-page-loader span{
  position: absolute;
  top: 50%;
  left: 48%;
width: 50px !important;
height: 50px !important;
}


/* .prompts .css-prxhy2-MuiFormLabel-root-MuiInputLabel-root{
  top: -6px !important
}

.css-prxhy2-MuiFormLabel-root-MuiInputLabel-root{
  top: -6px !important
}

.prompts .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  padding: 10px 14px;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  padding: 10px 14px;
} */

.profile-match-btn{
  border: 0 !important;
  background: none !important;
  color: #016E2A !important;
  text-transform: capitalize !important;
  font-size: 1rem !important;
  font-family: 'Nunito Sans', sans-serif;
  cursor: pointer;
}

.MuiCard-root{
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 10px;
}

.MuiDrawer-root{
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}
.pre-loader {
  display: 'flex';
  justify-content: center; 
  align-items: 'center';
  position: 'absolute'; 
  left:'0';
  top: '50%';
   background:'#c3c3c3';
    width:'100%';
     margin:'0 auto'  
}

.loader{
  position: relative;
}

.loader div{
  position: absolute;
  width: 100%;
  background: #ffffff5e;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 445px;
  height: auto;
  z-index: 10;
}

#button-form{
  color: #1976d2 !important;
}

.button-ta {
  background-color: #1976d2 !important;
  width: 350px;
}
.button-ta a{
  color: #fff !important;
}

.css-1fdky6o-MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .css-1fdky6o-MuiDataGrid-root .MuiDataGrid-cell:focus {
  outline: none !important;
}
/* #demo-simple-select-label{
  top: 0px;
} */

.pro-loader div{
  z-index: 1050;
  position: absolute;
  opacity: 1;
  background-color: #ffffff94;
  width: 100%;
  height: 100%;
  margin-bottom: 400px;
}
.pro-loader div .span{
  position: absolute;
  top: 50%;
  left: 50%;
}
.span{
  background-color: none !important;
}
.MuiDataGrid-cell:focus{
  outline: unset !important;
}

.css-cebc7e-MuiDataGrid-root .MuiDataGrid-cellContent{
  font-size: 14px;
}
/* .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
} */

.adduser_table .input-jobDes{
  width: 100%;
}
.pasword_input{
  height: 80px;
}


/* users name to move */
/* .css-mhc70k-MuiGrid-root>.MuiGrid-item {
  padding-top: 10px !important;
} */

/* prompts table to move up */
.css-nnz6xe-MuiGrid-root>.MuiGrid-item {
  padding-top: 0px !important;
}


/* prompts role  */
.css-6ejd34-MuiGrid-root>.MuiGrid-item {
  padding-top: 16px;
  margin-top: -15px !important;
}
.userView{
display: flex;

}
.JobExtractorView, .EmailCampaignView, .EmailCampaign1{
  display: flex;
}
.job-extractor .MuiDialog-container .MuiDialogContentText-root, .email-campaign .MuiDialog-container .MuiDialogContentText-root  {
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 15px;
}
.userView1, .jobExtractorView1{
  display: flex;
  }


/* prompts search */
/* .css-70qvj9 {
  margin-top: -20px !important;
} */


@media  screen and (min-width: 1920px) {
  .upload-sec .MuiBox-root {
    width: 185px;
}
}
 
@media screen and (min-width: 1680px) {
  .upload-sec .MuiBox-root {
    width: 150px;
}
}
 
 
@media screen and (min-width: 1440px) {
  .upload-sec .MuiBox-root {
    width: 120px;
}
}
@media (min-width: 901px) {
  main {
    width: 1200px;
  }
  .upload-sec .MuiBox-root {
    width: 150px;
}

}
@media (min-width: 320px) {
  main {
    width: 250px;
  }
  
  /* adjust the table width in users table in mobie view */
  

  .res-breadcrumbs.hidden {
    display: none;
  }
  
}
@media only screen and (max-width: 600px) {
  .userView{
    display: block;
    justify-content: center;
     align-items: center;

  }
  .css-6gw808-MuiTypography-root{
    margin-bottom: 1rem !important;
    text-align: center;
  }
  .userView1{
    display: block;
    justify-content: center;
  }
    .css-13i4rnv-MuiGrid-root {
      margin-bottom: 1rem !important;
      text-align: center;
    }

    /* .MuiDataGrid-columnHeader, .MuiDataGrid-cell{
      width: 160px !important;
      min-width: 160px !important;
      max-width: 160px !important;
    }
    .userPageView{
      display: block;
    }
    #profile_table_view .MuiDataGrid-columnHeader, .MuiDataGrid-cell{ 
      width: 200px !important;
      min-width: 200px !important;
      max-width: 200px !important;

    } */
}



@media (min-width: 768px) {
  main {
    width: 300px;
  }
  
}

/* @media screen and (max-width: 1024px){
 .MuiDataGrid-columnHeader, .MuiDataGrid-cell{
    width: 280px !important;
    min-width: 280px !important;
    max-width: 290px !important;
  }
} */

/* @media screen and (min-width: 1440px) {
  .MuiDataGrid-columnHeader, .MuiDataGrid-cell{
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }
  
} */

@media screen and (min-width: 0px) {
  .md-pd{
    margin-top: 10px !important;
  }
  .md-pd .css-18wyzaq-MuiGrid-root{
    width: 100% !important;
  }
}

@media screen and (min-width: 1440px ) and (max-width: 1680px) {

  .analysis-report-date{
    width: 250px;
  }
  
}


.spin-loder{
      position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

/* @media screen  and (max-width:768px){
  
  .MuiDrawer-docked .MuiDrawer-paper,.MuiDrawer-docked{
    width: 0 !important;
  }


  
} */
.spin-loder{
  position: absolute;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
align-content: center;
flex-wrap: wrap;
}


::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
  border-radius: 5px;
}

::-webkit-scrollbar
{
	width: 2px;
	background-color: #F5F5F5;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb
{
	background-color: #888888;
}
.MuiDataGrid-root::-webkit-scrollbar-thumb {
  border-radius: 5px;
  height: 2px;  
}
.MuiDataGrid-root::-webkit-scrollbar {
  height: 2px;
  
}

.campaign .MuiGrid-container {
  align-items: self-start;
}

@media screen and (max-width: 1540px){
  .MuiBadge-badge {
    left: 50% !important;
    text-align:center !important;
    transform: translate(-50%, -50%) !important;
    position: absolute !important;
    height: 15px !important;
    border: 1px solid #ececec;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.MuiDataGrid-cell {
  overflow: visible !important;
}
}

@media screen and (max-width: 1040px){
.MuiDataGrid-root{
overflow: scroll;
}
.MuiDataGrid-main{
width: 1200px;
overflow-x: scroll;
}
.MuiDataGrid-footerContainer{
width: 1200px;
}
.analysis-report-table.MuiDataGrid-root .MuiDataGrid-main{
  width: 1500px;
}
.analysis-report-table .MuiDataGrid-footerContainer{
  width: 1500px;
}
button{
  text-transform: capitalize !important;
}
.role-prompts-block button{
  width: 170px;
}
.button-j, .button-r{
  width: 200px;
}
.campaign .MuiGrid-container {
  align-items: self-start;
}
}
@media screen and (max-width:768px) {
  .job-desss .job-content div.grid-btn{
    /* margin-right: 4rem !important; */
    padding-top: 0 !important;
    margin-bottom: 0 !important;
    width: 100%;
  }

  .header-userList{
    margin-right: 0px !important;
  }
  .navigation-block, .toolbar {
    padding-right: 0 !important;
  }
  .logo {
    width: 75px !important;
  }
 .header-userList .first, 
 .header-userList .second, 
 .header-userList .third{
  font-size: 0.6rem;
  padding-right: 10px;
 }
  .user-prompts,  .role-prompts-table-first{
    padding-right: 0 !important;
  }
  .role-prompts-block{
    display: block !important;
    margin-left: 16px !important;
  }

  .role-prompts-block div:first-child{
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .analysis_report_drop{
    margin-bottom: 20px !important;
  }
  .campaign {
    padding: 20px 5px !important;
    text-align: left;
  }
  .campaign h6 {
    font-size: 10px;
  }

  .campaign button, .JobExtractorView button {
    margin-top: 25px;
  }
  .not-icon {
    bottom: 0px !important;
    right: 116px !important;
    width: 10px !important;
    height: 10px !important;
  }
  .campaign .MuiSvgIcon-root {
    width: 0.5em !important;
    height: 0.5em !important;
  }
  .tool-box .content, .functions .content {
    font-size: 10px;
  }
  .campaign.dd, .JobExtractorView .dd{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}




/*Loader Css*/


.profileMatching-loader {
  --background: linear-gradient(135deg, #23C4F8, #1976d2);
  --shadow: rgba(39, 94, 254, 0.28);
  --text: #6C7486;
  --page: rgba(255, 255, 255, 0.36);
  --page-fold: rgba(255, 255, 255, 0.52);
  --duration: 3s;
  width: 160px !important;
  height: 110px !important;
  position: relative;
}
.profileMatching-loader:before, .profileMatching-loader:after {
  --r: -6deg;
  content: "";
  position: absolute;
  bottom: 8px;
  width: 120px;
  top: 80%;
  box-shadow: 0 16px 12px var(--shadow);
  transform: rotate(var(--r));
}
.profileMatching-loader:before {
  left: 4px;
}
.profileMatching-loader:after {
  --r: 6deg;
  right: 4px;
}
.profileMatching-loader .loader-div {
  width: 100%;
  height: 100%;
  border-radius: 13px;
  position: relative;
  z-index: 1;
  perspective: 600px;
  box-shadow: 0 4px 6px var(--shadow);
  background-image: var(--background);
}
.profileMatching-loader .loader-div ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}
.profileMatching-loader .loader-div ul li {
  --r: 180deg;
  --o: 0;
  --c: var(--page);
  position: absolute;
  top: 10px;
  left: 0px;
  transform-origin: 88% 50%;
  color: var(--c);
  opacity: var(--o);
  transform: rotateY(var(--r));
  -webkit-animation: var(--duration) ease infinite;
          animation: var(--duration) ease infinite;
}
.profileMatching-loader .loader-div ul li:nth-child(2) {
  --c: var(--page-fold);
  -webkit-animation-name: page-2;
          animation-name: page-2;
}
.profileMatching-loader .loader-div ul li:nth-child(3) {
  --c: var(--page-fold);
  -webkit-animation-name: page-3;
          animation-name: page-3;
}
.profileMatching-loader .loader-div ul li:nth-child(4) {
  --c: var(--page-fold);
  -webkit-animation-name: page-4;
          animation-name: page-4;
}
.profileMatching-loader .loader-div ul li:nth-child(5) {
  --c: var(--page-fold);
  -webkit-animation-name: page-5;
          animation-name: page-5;
}
.profileMatching-loader .loader-div ul li svg {
  width: 90px;
  height: 90px;
  display: block;
}
.profileMatching-loader .loader-div ul li:first-child {
  --r: 0deg;
  --o: 1;
}
.profileMatching-loader .loader-div ul li:last-child {
  --o: 1;
}
.profileMatching-loader div.span {
  display: block;
  left: -86px !important;
  right: 0px;
  top: 100% !important;
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
  text-align: center;
  color: var(--text);
   animation: blink 1.5s infinite;
   width: 350px;
   background-color: transparent !important;
}

@-webkit-keyframes page-2 {
  0% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  35%, 100% {
    opacity: 0;
  }
  50%, 100% {
    transform: rotateY(0deg);
  }
}

@keyframes page-2 {
  0% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  35%, 100% {
    opacity: 0;
  }
  50%, 100% {
    transform: rotateY(0deg);
  }
}
@-webkit-keyframes page-3 {
  15% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
  50%, 100% {
    opacity: 0;
  }
  65%, 100% {
    transform: rotateY(0deg);
  }
}
@keyframes page-3 {
  15% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  35% {
    opacity: 1;
  }
  50%, 100% {
    opacity: 0;
  }
  65%, 100% {
    transform: rotateY(0deg);
  }
}
@-webkit-keyframes page-4 {
  30% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  65%, 100% {
    opacity: 0;
  }
  80%, 100% {
    transform: rotateY(0deg);
  }
}
@keyframes page-4 {
  30% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  65%, 100% {
    opacity: 0;
  }
  80%, 100% {
    transform: rotateY(0deg);
  }
}
@-webkit-keyframes page-5 {
  45% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  80%, 100% {
    opacity: 0;
  }
  95%, 100% {
    transform: rotateY(0deg);
  }
}
@keyframes page-5 {
  45% {
    transform: rotateY(180deg);
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  80%, 100% {
    opacity: 0;
  }
  95%, 100% {
    transform: rotateY(0deg);
  }
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.profile-loader {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1C212E;
  font-family: "Roboto", Arial;
}
.profile-loader .dribbble {
  position: fixed;
  display: block;
  right: 24px;
  bottom: 24px;
}
.profile-loader .dribbble img {
  display: block;
  width: 76px;
} 


/*loader-25*/

.loader-25 {
  border: 0.3em #1976d2 solid;
  width: 3em; /* Change the width */
  height: 4em; /* Change the height */
  position: relative;
  -webkit-animation: loader-25-1 5s linear infinite;
          animation: loader-25-1 5s linear infinite;
}

.loader-25:after {
  width: 0.5em; /* Change the width */
  height: 0.5em; /* Change the height */
  position: absolute;
  content: "";
  background-color: #1976d2;
  bottom: calc(100% + .2em);
  left: -0.4em;
  -webkit-animation: loader-25-2 1s ease-in-out infinite;
          animation: loader-25-2 1s ease-in-out infinite;
}

@-webkit-keyframes loader-25-1 {
  0% {
    box-shadow: inset 0 0 0 0 #1976d2;
  }
  100% {
    box-shadow: inset 0 -4em 0 0 #1976d2;
  }
}

@keyframes loader-25-1 {
  0% {
    box-shadow: inset 0 0 0 0 #1976d2;
  }
  100% {
    box-shadow: inset 0 -4em 0 0 #1976d2;
  }
}
@-webkit-keyframes loader-25-2 {
  25% {
    left: calc(100% + .2em);
    bottom: calc(100% + .2em);
  }
  50% {
    left: calc(100% + .2em);
    bottom: -0.4em;
  }
  75% {
    left: -0.4em;
    bottom: -0.4em;
  }
  100% {
    left: -0.4em;
    bottom: calc(100% + .2em);
  }
}
@keyframes loader-25-2 {
  25% {
    left: calc(100% + .2em);
    bottom: calc(100% + .2em);
  }
  50% {
    left: calc(100% + .2em);
    bottom: -0.4em;
  }
  75% {
    left: -0.4em;
    bottom: -0.4em;
  }
  100% {
    left: -0.4em;
    bottom: calc(100% + .2em);
  }
}

.MuiSvgIcon-colorPrimary {
  color: #1976d2 !important ;
}



.MuiCollapse-root .MuiButtonBase-root:hover{
  background-color: #1976d2;
  color: #fff;
}
.sample{
  color: #fff !important;
}
.MuiListItemButton-root:hover .MuiSvgIcon-root, 
/* /* .css-oqylnv-MuiButtonBase-root-MuiListItemButton-root */
 .MuiListItemIcon-root .MuiSvgIcon-root
.MuiAlert-icon .MuiSvgIcon-root, .MuiAlert-action .MuiSvgIcon-root, .MuiDialog-paper .MuiSvgIcon-root{
  color: #fff;
}


.ant-upload .pdf-icon{
  color: #016E2A !important;
}

.MuiDataGrid-main .MuiDataGrid-columnHeaders {
  background-color: #1976d2;
  color: #fff;
}

.campaign{
  box-shadow: 0px 0px 15px rgba(0,0,0,0.15);
  border-radius: 5px;
  padding: 45px 30px;
  margin-top: 10px;
  text-align: center;
}

.campaign .heading{
  font-size: medium;
  font-weight: 600;
}
.blocked_companies{
  position: relative;
  display: flex;
  justify-content: space-between;
}
.card-1{
  margin-top: 10px;
}

.blocked-content{
  display: flex;
  align-items: center;
  padding: 10px;
  background: linear-gradient(to right, #fea402 51%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .5s ease-out;
}
.blocked-content .content {
  padding-left: 15px;
  margin-top: 3px;
}

.blocked-content:hover {
  background-position: left bottom;
  color: #fff;
}
.blocked-content:hover .content {
  color: #fff;
}
.campaign .blocked-content:hover .MuiSvgIcon-root {
  fill: #fff;
}
.business-icon, .icon {
  font-size: 3rem !important;
  color: #1976d2;
}
.notinterested-icon{
  font-size: 1.5rem !important;
  color: #1976d2;
  position: absolute;
    top: -2px;
    left: -2px;
}
.not-icon {
  background-color: #fff;
  position: absolute;
  bottom: 33px;
  right: 45px;
  width: 20px;
  height: 20px;
  border-radius: 50px;
}
.remove-icon{
  background-color: #fff;
  position: absolute;
  bottom: 35px;
  right: 55px;
  width: 20px;
  height: 20px;
  border-radius: 50px;
}
.campaign .btn{
  background-color: #1976d2;
  color: #fff;
  border: 1px ;
  border-radius: 3px;
  padding: 20px 0;
  width: 100%;
  font-size: 17px;
  font-weight: 700;
  margin: 20px auto;
}
.campaign .tool-box-heading {
  background-color: #1976d2;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #fff;
  text-align: center;
}
.campaign .first-block, .campaign .second-block {
  border: 1px solid #c3c3c3;
}

.tool-box , .functions{
  padding: 15px;
}
.content a {
  font-weight: 400;
  color: #000;
}
a {
  text-decoration: none;
}

.job-extractor-table .MuiDataGrid-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-r {
  width: 250px;
  margin-left: 15px;
  height: 40px;
}

.MuiSvgIcon-root {
  width: 0.8em;
  height: 0.8em;
}

.bottom-line {
  position: relative;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 10px 0 !important;
}


.bottom-line::after {
  content:'';
  position: absolute;
  background-color: #1976d2;
  width: 12%;
  height: 3px;
  bottom: 0;
  left:0;
}

.icon-important {
  width: 0.8em !important;
  height: 0.8em !important;
  color: #fff !important;
}
.download-icon div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1976d2;
  border-radius: 50px;
  width: 28px;
  height: 28px;
}
.download-icon div svg {
  width: 0.8em;
  height: 0.8em;
  color: #fff;
}

.download-icon div:hover{
  width: 28px;
  height: 28px;
  background-color: #b9bbbc;
  border-radius: 50px;
}
.download-icon div:hover svg {
  color: #1976d2;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
  font-family: Arial, sans-serif;
}

.custom-dropdown-toggle {
  padding: 10px 16px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
}

.custom-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 4px;
  z-index: 1000;
}

.custom-dropdown-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
  background-color: white;
}

.custom-dropdown-item:last-child {
  border-bottom: none;
}

.custom-dropdown-item:hover {
  background-color: #f9f9f9;
}

.custom-dropdown-item.selected {
  background-color: #1976d2;
  color: #fff;
}

.custom-radio {
  width: 18px;
  height: 18px;
  border: 2px solid #ccc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  position: relative;
}

.custom-dropdown-item.selected .custom-radio-inner {
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  display: none;
}

.custom-dropdown-item.selected .custom-radio-inner {
  display: block;
}


fieldset {
  border: 2px solid #1976d2;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 10px 20px;
}

legend {
  background-color: #1976d2;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  text-align: left;
}

.manage-user .password{
  color: #333 !important;
}

.placeholder{
  position: relative;
}
.placeholder::after {
  border-top: 1px solid #aaa9a8;
  content: "";
  margin: 0 auto;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.editor-toolbar-group {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  margin-right: 10px;
}

.editor-label {
  font-size: 14px;
  color: #333;
  font-weight: 500;
}

.job-extractor .MuiDialog-container .MuiTypography-root{
  padding-top: 0;
  padding-bottom: 0;
}
.ql-editor{
  overflow-y: hidden !important;
}
.ql-editor:hover{
  overflow-y: auto !important;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options{
  max-height: 200px;
  overflow-y: hidden;
  transition: overflow-y 0.3s ease;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options:hover{
  overflow-y: auto;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-options::-webkit-scrollbar-track,.ql-editor::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #380000;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options::-webkit-scrollbar,.ql-editor::-webkit-scrollbar {
  width: 6px;
  background-color: #d6d6d6;
  transition: background-color 0.3s ease;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options::-webkit-scrollbar-thumb, .ql-editor::-webkit-scrollbar-thumb {
  background-color: #d6d6d6;
  opacity: 0;
  transition: opacity 0.3s ease, background-color 0.3s ease;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options:hover::-webkit-scrollbar-thumb, .ql-editor::-webkit-scrollbar-thumb {
  opacity: 1;
  background-color: #888888; 
}

#drop-area {
  padding: 30px;
}

.campaign .MuiSvgIcon-root {
  width: 0.6em;
  height: 0.6em;
}

@media screen and (min-width:1400px) {
  .not-icon , .remove-icon {
    left: 19px;
    width: 0.6em;
    bottom: 3px;
    height: 0.6em;
  }
}